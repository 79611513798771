import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { requestResetPasswordService } from '../../services/notifications/resetPasswordRequest';


export default function ForgotPasswordComponent() {
    const [hasError, setHasError] = useState();
    const [email, setEmail] = useState();

    const sendRequest = async (event) => {
        event.preventDefault();

        if (email == null || email == undefined) {
            setHasError(() => {

                return (
                    <>
                        <Alert id="errorMessage" key="danger" variant="danger">
                            Enter Email Address
                        </Alert>
                    </>
                )
            })
        } else {
            var blah = await requestResetPasswordService(email);
            if (blah?.code == 2) {
                setHasError(() => {
                    return (
                        <>
                            <Alert id="errorMessage" key="danger" variant="danger">
                                User Not Found
                            </Alert>
                        </>
                    )
                })
            } else {
                setHasError(false);
                setHasError(() => {
                    return (
                        <>
                            <Alert key="success" variant="success">
                                If an account is associated with this email, a password reset link will be sent to you within a few minutes.
                            </Alert>

                        </>
                    )
                })

            }

            // setHasError(blah)
        }
    }

    return (
        <div >
            <h1 id="resetHeader">Forgot Password?</h1>
            <Card >
                <Card.Body>
                    <Card.Text>
                        <Form>
                            <label for="emailField">Email*</label>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                {/* <Form.Label>Username</Form.Label> */}
                                <Form.Control
                                    aria-describedby={hasError ? 'errorMessage' : null} id="emailField"
                                    autoComplete='email' type="email"
                                    onChange={event => {
                                        setHasError(false);
                                        return setEmail(event.target.value);
                                    }} required />
                                <Card.Subtitle style={{ marginTop: '12px', marginBottom: '12px' }} className="mb-2 text-muted">Enter the email address on your account, and click reset password</Card.Subtitle>
                                <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text>

                            </Form.Group>
                            <Button type="submit" onClick={sendRequest}>
                                Reset Password
                            </Button>
                            <div align="right">
                                <small>
                                    <p>* indicates a required field</p>
                                </small>
                            </div>
                        </Form><br />
                        {hasError}
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );


}